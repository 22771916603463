<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Enquiries</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Enquiries"
            outlined
            v-model="searchTerm"
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{
                name: 'module-lpb-enquiries',
              }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Enquiries
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="enquiries"
            no-data-text="No enquiries"
            :items-per-page="-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="yellow lighten-4 yellow--text text--darken-3"
                    v-on="on"
                    @click="openRestore(item)"
                  >
                    <v-icon small>mdi-restore</v-icon>
                  </v-btn>
                </template>
                <span>Restore</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Enquiry</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.enquiry.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Created Date", value: "formatted_dates.created_at" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        enquiry: {},
      },
    };
  },

  computed: {
    enquiries() {
      let enquiries = this.$store.getters["lpb/enquiries/archived"];

      if (this.searchTerm !== "") {
        enquiries = enquiries.filter((c) => {
          const name = c.name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return enquiries;
    },
  },

  methods: {
    openRestore(enquiry) {
      this.restoreDialog.enquiry = enquiry;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.enquiry = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("lpb/enquiries/restoreEnquiry", {
          appId,
          enquiryId: this.restoreDialog.enquiry.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
